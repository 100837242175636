<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <InputOptionSmall
        :letter="'a'"
        :option="'Male'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-gender-male.png'"
        :selected="personalDetails.sex === 'Male'"
        @selected="save('Male')"
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'b'"
        :option="'Female'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-gender-female.png'"
        :selected="personalDetails.sex === 'Female'"
        @selected="save('Female')"
      ></InputOptionSmall>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="backTo"
        v-bind:center="false"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputOptionSmall from '@/common/ui/InputOptionSmall'

export default {
  name: 'ProfileGender',
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputOptionSmall
  },
  mounted() {
    this.$emit('progress', '30%')
  },
  computed: {
    background() {
      if (this.start) return 'bg-personal-details-cookie'
      return 'bg-personal-details'
    },
    number: () => '3',
    start() {
      return this.$router.history.current.name === 'WriteMyWillGender'
    },
    forwardTo() {
      if (this.start) return '/write_my_will/children'
      return '/profile/children'
    },
    backTo() {
      if (this.start) return '/write_my_will/dob'
      return '/profile/dob'
    },
    heading() {
      return 'What is your gender?'
    },
    subHeading() {
      return 'This choice will alter the pronouns used throughout your Will i.e his or her.'
    },
    personalDetails() {
      return this.$store.getters.personalDetails
    },
    isValidated() {
      return !!this.personalDetails.sex
    }
  },
  beforeRouteLeave(to, from, next) {
    this.save()
    next()
  },
  methods: {
    save(value) {
      if (this.personalDetails) {
        this.$store.commit('personalDetails', {
          sex: value
        })
      }
    }
  }
}
</script>

<style></style>
